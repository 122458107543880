// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/CV.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-foto-js": () => import("./../../../src/pages/foto.js" /* webpackChunkName: "component---src-pages-foto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kode-js": () => import("./../../../src/pages/kode.js" /* webpackChunkName: "component---src-pages-kode-js" */),
  "component---src-pages-musikk-js": () => import("./../../../src/pages/musikk.js" /* webpackChunkName: "component---src-pages-musikk-js" */),
  "component---src-pages-tekst-js": () => import("./../../../src/pages/tekst.js" /* webpackChunkName: "component---src-pages-tekst-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

